
























































import { Aluno, Turma } from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
import { AlunoService, TurmaService } from "@/core/services/geral";
import {Component, Watch} from "vue-property-decorator";

@Component
export default class ConfiguracaoFinanceiro extends PageBase{
    valid: boolean = true;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    $refs!: {
        form: HTMLFormElement
    }
    filtro: any = {
        turmaId: null,
        alunoId: null,
        pago: null,
        vencimentoDataInicio: '',
        vencimentoDataFim: '',
        pagamentoDataInicio: '',
        pagamentoDataFim: '',
        layout: 0
    }

    isTurmaLoading: boolean = false;
    onSearchTurma: any = null;
    turmas: Turma[] = [];
    turmaService: TurmaService = new TurmaService();

    isAlunoLoading: boolean = false;
    onSearchAluno: any = null;
    alunos: Aluno[] = [];
    alunoService: AlunoService = new AlunoService();

    situacoes: any[] = [
        {id: null, nome: 'TODOS'},
        {id: true, nome: 'PAGOS'},
        {id: false, nome: 'EM ABERTO'}
    ]

    layouts: any[] = [
        {id: 0, nome: 'PADRÃO'},
        {id: 1, nome: 'TURMA'},
        {id: 2, nome: 'CRACHÁ'},
    ]

    mounted() {
        this.turmaService.ListarAbertas().then(
            res=> {
                this.turmas = res.data.items
            }
        )
    }

    GerarRelatorio(){
        if(this.$refs.form.validate()){
            
            if(this.filtro.layout <= 1){
                let routeLink = this.$router.resolve({name: "impressaoFinanceiro",
                    query:{
                        turmaNome: this.filtro.turmaId > 0 ? this.turmas.find(x => x.id == this.filtro.turmaId)!.cursoCodigo : '',
                        layoutId: this.filtro.layout,
                        turmaId: this.filtro.turmaId,
                        alunoId: this.filtro.alunoId,
                        pago: this.filtro.pago,
                        vencimentoDataInicio: this.filtro.vencimentoDataInicio,
                        vencimentoDataFim: this.filtro.vencimentoDataFim,
                        pagamentoDataInicio: this.filtro.pagamentoDataInicio,
                        pagamentoDataFim: this.filtro.pagamentoDataFim
                    }
                });
                window.open(routeLink.href,'_blank')
            }
            else{
                let routeLink = this.$router.resolve({name: "impressaoFinanceiro2",
                    query:{
                        turmaId: this.filtro.turmaId,
                        alunoId: this.filtro.alunoId,
                        pago: this.filtro.pago,
                        vencimentoDataInicio: this.filtro.vencimentoDataInicio,
                        vencimentoDataFim: this.filtro.vencimentoDataFim,
                        pagamentoDataInicio: this.filtro.pagamentoDataInicio,
                        pagamentoDataFim: this.filtro.pagamentoDataFim
                    }
                });
                window.open(routeLink.href,'_blank')
            }            
        }
    }

    LimparFiltros(){
        this.filtro = {
            turmaId: null,
            alunoId: null,
            pago: null,
            vencimentoDataInicio: '',
            vencimentoDataFim: '',
            pagamentoDataInicio: '',
            pagamentoDataFim: '',
            layout: 0
        }
        this.$refs.form.resetValidation();
    }

    @Watch('onSearchAluno')
    SearchAluno (val:string){
        if(this.filtro.alunoId)
            return;
        if(this.isAlunoLoading)
            return;
        if(!val)
            return;
        this.isAlunoLoading = true
        this.alunoService.AutoComplete(val).then(
        res =>{
            this.alunos = res.data;
        },
        err => this.$swal('Aviso',err.response.data,'error')
        ).finally(() =>{
            this.isAlunoLoading = false;
        })
    }

    @Watch('filtro.layout')
    WatchLayout(){
        if(this.filtro.layout == 1) {
            this.filtro.pago = null;
            this.filtro.vencimentoDataInicio = '';
            this.filtro.vencimentoDataFim = '';
            this.filtro.pagamentoDataInicio = '';
            this.filtro.pagamentoDataFim = '';
        }
    }
}
